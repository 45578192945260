/* eslint-disable canonical/filename-match-exported */
import React from 'react'
import { Container, Layout } from '../components'
import Profile from '../components/content/Profile'
import Head from '../services/Head'

const ImprintPage = () => {
  return (
    <div className='overflow-x-hidden'>
      <Head />
      <Layout hideSidebarOnMobile sidebarContent={<Profile />}>
        <div className='px-3 py-10 sm:px-6 sm:py-12'>
          <Container className='w-full space-y-14'>
            <div>
              <div className='headline'>Impressum</div>
              <div className='mt-3 mb-8 text-xs text-content'>
                Informationspflicht lt. §5 E-Commerce Gesetz, §14
                Unternehmensgesetzbuch bzw. §63 Gewerbeordnung und
                Offenlegungspflicht lt. §25 Mediengesetz
              </div>
              <div>
                <p>
                  <strong>Lorenz Nimmervoll</strong>
                </p>
                <p>Forstnerweg 12</p>
                <p>4112 St. Gotthard i. M.</p>
                <p>Österreich</p>
                <br />
                <p>
                  <strong>Email</strong>:{' '}
                  <a className='link' href='mailto:office@nimmervoll.work'>
                    office@nimmervoll.work
                  </a>
                </p>
                <p>
                  <strong>Telefon</strong>: +4367761351715
                </p>
                <p>
                  <strong>Web</strong>:{' '}
                  <a className='link' href='https://nimmervoll.work'>
                    nimmervoll.work
                  </a>
                </p>
                <br />
                <p>
                  <strong>UID-Nr:</strong> ATU76847637
                </p>
                <br />
                <p>
                  <strong>Unternehmensgegenstand</strong>: Entwicklung und
                  Programmierung von Softwaresystemen, insbesondere von
                  Cloudlösungen
                </p>
                <br />
                <p>
                  <strong>Rechtsform</strong>: Einzelunternehmen
                </p>
                <br />

                <p>
                  <strong>Mitglied der</strong> WKÖ, WKOÖ, FG
                  Unternehmensberatung, Buchhaltung und Informationstechnik, LG
                  Handelsagenten
                </p>
                <br />
                <br />

                <p>
                  <strong>Aufsichtsbehörde/Gewerbebehörde:</strong>{' '}
                  Bezirkshauptmannschaft Urfahr-Umgebung
                </p>
                <p>
                  Anwendbare Rechtsvorschriften: Berufsrecht, Gewerbeordnung:{' '}
                  <a
                    className='link'
                    href='https://www.ris.bka.gv.at'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    www.ris.bka.gv.at
                  </a>
                </p>

                <br />
                <p>
                  <strong>Angaben zur Online-Streitbeilegung</strong>:
                </p>
                <p>
                  Verbraucher haben die Möglichkeit, Beschwerden an die Online
                  Streitbeilegungsplattform der EU zu richten:{' '}
                  <a
                    className='link'
                    href='https://ec.europa.eu/odr'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    https://ec.europa.eu/odr
                  </a>
                  . Sie können allfällige Beschwerde auch an die oben angegebene
                  E-Mail-Adresse richten.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default ImprintPage
